import React, { useEffect, useState } from "react";
import "../App.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { Link } from "react-router-dom";
import partner1 from "../Photos/aff_logo_1.jpeg";
import partner2 from "../Photos/aff_logo_2.jpeg";
import partner3 from "../Photos/aff_logo_3.jpeg";
import partner4 from "../Photos/aff_logo_4.jpeg";
import AECHSCampus from "../Photos/AECHSCampus.png";
import ChakriCampus from "../Photos/ChakriCampus.png";
import GolraMor from "../Photos/GolraMor.png";
import STCAMPUS from "../Photos/STCAMPUS.png";
import SeniorWestridgeCampus from "../Photos/SeniorWestridgeCampus.png";
import F8Campus from "../Photos/F8Campus.jpg";
import HarleyCampus from "../Photos/HarleyCampus.png";
import JuniorWestridge from "../Photos/JuniorWestridge.png";
import PWDcampus from "../Photos/PWDcampus.JPG";
import skanspeshawarroad from "../Photos/skanspeshawarroad.png";


import { backendURL } from "../constant";
import megaphone from "../Photos/megaphone.png";
import ReactPaginate from "react-paginate";
import Footer from "../Components/Footer";
import NavbarNew from "../Components/navbar";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Home1 = () => {
  const [counton, setCounton] = useState(false);
  const [counter, setCounter] = useState({
    counter1: 8,
    counter2: 7,
    counter3: 4000,
    counter4: 250,
  });
  const [announcement, setAnnouncment] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const universitiesPerPage = 3;

  const callAnnouncement = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow",
      };

      fetch(`${backendURL}/getHomeAnnouncement`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("announcement", result);

          const data = JSON.parse(result);
          console.log(data);
          setAnnouncment(data);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    callAnnouncement();
  }, []);
  const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
  const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
  const currentAnnouncment = announcement.slice(
    indexOfFirstUniversity,
    indexOfLastUniversity
  );
  const images = [
    AECHSCampus,
    ChakriCampus,
    GolraMor,
    STCAMPUS,
    SeniorWestridgeCampus,
    F8Campus,
    HarleyCampus,
    JuniorWestridge,
    PWDcampus,
    skanspeshawarroad
  ];
  return (
    <>
      <div></div>

      <div className="index-page">
        <Header />

        <main className="main">
          <HeroSection />

          <section id="details" class="details section mt-3 pt-2">
            <div class="container section-title text-center" data-aos="fade-up">
              {/* <h2>Our Campuses</h2> */}
              <div>
                <span class="description-title text-white text-center bg-dark-blue py-2 px-4 rounded">
                Our Campuses
                </span>
              </div>
            </div>
{/* 
            <div class="container">
              <div class="row gy-4 align-items-center features-item">
                <div
                  class="col-md-5 d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay="100"
                >
                  <img
                    src="	https://skans.pk/static/media/F8Campus%20-%20Copy.2ccb14b50db77a8e76b2.jpg"
                    class="img-fluid rounded-4"
                    alt=""
                  />
                </div>
                <div class="col-md-7" data-aos="fade-up" data-aos-delay="100">
                  <p class="fst-italic fs-5">
                    SKANS is the acronym of an institution, which stands for
                    Skills, Knowledge and Application Nurtured through
                    Student-managed participative learning approach. It aims to
                    be one of the leading business schools in the field of
                    Professional Accountancy Education, catering to the students
                    needs in a proactive manner. It has developed an expertise
                    in the realm of CA (Pak), ACCA (UK), CIMA (UK), CAT (UK),
                    CISA(USA).
                  </p>
                </div>
              </div>
            </div> */}
            <Swiper
            slidesPerView={3}
        spaceBetween={30}
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper p-3 pt-0"
      >
        {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image} alt={`Slide ${index + 1}`} className=" img-fluid rounded-4" style={{height: "30vh" }} />
        </SwiperSlide>
      ))}
      </Swiper>
          </section>
          <ScrollTrigger
            onEnter={() => setCounton(true)}
            onExit={() => setCounton(false)}
          >
            <section id="stats" class="stats section light-background">
              <div class="container">
                <div class="row gy-4">
                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-emoji-smile"></i>
                    <div class="stats-item">

                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter1}
                          duration={2}
                          delay={0}
                          suffix="+" 
                        />
                      )}
                      <p>Schools</p>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-building"></i>
                    <div class="stats-item">
                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter2}
                          duration={2}
                          delay={0}
                          suffix="+"
                        />
                      )}
                      <p>Colleges</p>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-people"></i>
                    <div class="stats-item">
                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter3}
                          duration={2}
                          delay={0}
                          suffix="+"
                        />
                      )}
                      <p>Students</p>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                    <i class="bi bi-people"></i>
                    <div class="stats-item">
                      {counton && (
                        <CountUp
                          start={0}
                          end={counter.counter4}
                          duration={2}
                          delay={0}
                          suffix="+"
                        />
                      )}
                      <p>Faculty</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ScrollTrigger>

          <div className=" overflow-hidden mb-5">
            <div
              className="pb-5 text-center mt-5"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1500"
            >
              <h1 className="fs-1">
                We're not the only ones <span className="fw-bold">excited</span>{" "}
                about{" "}
                <span
                  className=" fontcolordark fw-bold"
                  style={{ fontFamily: "serif" }}
                >
                  SKANS
                </span>
              </h1>
              <p className="fs-3 text-primary">
                25,000 + Students Are Part Of{" "}
                <span
                  className=" fontcolordark fw-bold"
                  style={{ fontFamily: "serif" }}
                >
                  SKANS
                </span>
                .
              </p>
            </div>
            <div className="marquee-container">
              <div className="marquee">
                <div className="marquee-content">
                  <img src={partner1} />
                  <img src={partner2} />
                  <img src={partner3} />
                  <img src={partner4} />
                  <img src={partner1} />
                  <img src={partner2} />
                  <img src={partner3} />
                  <img src={partner4} />
                  <img src={partner1} />
                  <img src={partner2} />
                  <img src={partner3} />
                  <img src={partner4} />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid d-flex d-block flex-wrap my-1 overflow-hidden">
            {/* <div className='col-lg-9 col-12' data-aos="fade-right">
          <ObjectiveSection />
        </div> */}
            <div
              className="d-flex flex-column bg-lightgrey rounded-3 container p-2 cta_bg"
              data-aos="fade-left"
            >
              <div className="text-center d-flex align-items-center">
                <div className="col-3 d-flex align-items-center">
                  <img src={megaphone} width={"30%"}></img>
                </div>
                <div className="col-6">
                  <h5 className="textdarkblue fw-bold py-3 text-center">
                    General Announcement
                  </h5>
                </div>
              </div>

              <div className=" border border-start-0 border-end-0 my-0 py-3">
                {currentAnnouncment && currentAnnouncment.length > 0 ? (
                  currentAnnouncment.map((high, id) => (
                    <div
                      key={id}
                      class="sample mb-3"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div class="ribbon down" style={{ color: "#fd9c2e" }}>
                        <div class="content">{high.date}</div>
                      </div>
                      <div className="p-5 px-3">
                        <h4 className="fontcolordark pt-4 fw-bold">
                          {high.title}
                        </h4>
                        <p className="text-truncate">{high.description}</p>
                        <Link
                          className="text-primary"
                          to={`/AnnouncementDetail/${high.id}`}
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Not Found</p>
                )}
              </div>

              <ReactPaginate
                pageCount={Math.ceil(announcement.length / universitiesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                activeClassName="active"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLabel={<i className="bi bi-chevron-left"></i>}
                nextLabel={<i className="bi bi-chevron-right"></i>}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

const Header = () => {
  const toggleMobileMenu = () => {
    document.querySelector("body").classList.toggle("mobile-nav-active");
    document.querySelector(".mobile-nav-toggle").classList.toggle("bi-list");
    document.querySelector(".mobile-nav-toggle").classList.toggle("bi-x");
  };
  return (
    <header
      id="header"
      className="header d-flex align-items-center fixed-top d-flex flex-column"
    >
      <NavbarNew />
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">
          <img
            width={"200px"}
            className="img-fluid"
            src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
          ></img>
        </a>
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <Link to="/" className="active">
                Home
              </Link>
            </li>
            <li>
              <Link to="/SkansGallery">Life At SKANS</Link>
            </li>
            <li>
              <Link to="/MeritStudents" className="text-danger">
                High Achievers
              </Link>
            </li>
            <li>
              <Link to="/CAProgram" className="">
                CA
              </Link>
            </li>
            <li>
              <Link to="/ACCAProgram" className="">
                ACCA
              </Link>
            </li>
            <li>
              <Link to="/ContactUs">Contact</Link>
            </li>

            <li>
              <a
                style={{ fontSize: "14px" }}
                href="/onlinepayments/challans.php"
              >
                Online Challan Payment
              </a>
            </li>
          </ul>
          <i
            className="mobile-nav-toggle d-xl-none bi bi-list"
            onClick={toggleMobileMenu}
          ></i>
        </nav>
      </div>
    </header>
  );
};

const HeroSection = () => {
  return (
    <section className="hero section dark-background">
      <img
        src={require("../Photos/skansbackground.gif")}
        alt="Hero Background"
        className="hero-bg"
      />
      <div className="container">
        <div className="row gy-4 justify-content-between">
          <div
            className="col-lg-6 d-flex flex-column justify-content-center"
            data-aos="fade-in"
          >
            <h1>
              Welcome to <br></br>
              <span>SKANS SCHOOLS OF ACCOUNTANCY</span>
            </h1>
            <p>
              SKANS is the acronym of an institution, which stands for Skills,
              Knowledge and Application Nurtured through Student-managed
              participative learning approach.
            </p>
            <div className="d-flex">
              <Link to="/SchoolTour" className="btn-get-started fw-bold">
                Visit Schools Website
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/CollegeVirtualTour" className="btn-get-started fw-bold">
                Visit Colleges Website
              </Link>
            </div>
          </div>
        </div>
      </div>
      <svg
        className="hero-waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id="wave-path"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          ></path>
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3"></use>
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0"></use>
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9"></use>
        </g>
      </svg>
    </section>
  );
};

export default Home1;

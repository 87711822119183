import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../CSS/multiCarousel.css"
import { backendURL } from "../constant";
// import achiever1 from "../Photos/achiever1.jpeg"
// import achiever2 from "../Photos/achiever2.jpeg"
// import achiever3 from "../Photos/achiever3.jpeg"
// import achiever4 from "../Photos/achiever4.jpeg"
// import achiever5 from "../Photos/achiever5.jpeg"
// import achiever6 from "../Photos/achiever6.jpeg"
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1
  }
};

function Slider({ achieversData }) {

  // const [highachiever,setHighAchiever]= useState({photos:""});
  // const achieversData = [
  //   {
  //     photos: "https://backend.skans.pk/public/uploads/photos_1711350641630.jpg",
  //     name: "Sarah Johnson",
  //     achievement: "Gold Medalist 2023"
  //   },
  //   {
  //     photos: "https://backend.skans.pk/public/uploads/photos_1711350641612.jpg", 
  //     name: "Michael Chen",
  //     achievement: "Best Student Award"
  //   },
  //   {
  //     photos: "https://backend.skans.pk/public/uploads/photos_1711350641649.jpg",
  //     name: "Emma Davis",
  //     achievement: "Academic Excellence"
  //   },
  //   {
  //     photos: "https://backend.skans.pk/public/uploads/photos_1711350641763.jpg",
  //     name: "James Wilson",
  //     achievement: "Outstanding Performance"
  //   },
  //   {
  //     photos: "https://backend.skans.pk/public/uploads/photos_1711350641649.jpg",
  //     name: "Sophia Garcia",
  //     achievement: "Merit Scholar"
  //   }
  // ];
  // const callAchievers = async () => {
  //   try {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");



  //     const requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,

  //       redirect: "follow"
  //     };

  //     fetch(`${backendURL}/getHomeHighAchiever`, requestOptions)
  //       .then((response) => response.text())
  //       .then(async (result) => {
  //         console.log("highlight", result);

  //         const data = JSON.parse(result);
  //         console.log(data);
  //         setHighAchiever(data);

  //       })
  //       .catch((error) => console.error(error));
  //   } catch (err) {
  //     console.log(err);

  //   }
  // };



  // useEffect(() => {

  //   callAchievers();
  // }, []);
  const picurl = `${backendURL}/public/uploads/`;
  return (
    <div  style={{width:"100%"}}>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={2000}
        swipeable={true}
        draggable={true}
        infinite={true}
        partialVisible={false}
        className="z-1"
      >
       {Array.isArray(achieversData) && achieversData.length>0 ? achieversData.map((photos, index) => {
          return (
            <div className="slider mx-sm-3 m-0" key={index}>
              {/* <img className="img-fluid" src={picurl+imageUrl.photos } alt="skans" /> */}
              <img className="img-fluid" src={photos.photos} alt="skans" />

            </div>
          );
        }):(<p>loading...</p>)}
      </Carousel>
    </div>
  );
};
export default Slider;

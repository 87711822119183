import React from "react";
import "../CSS/collegetour.css";
import "../CSS/schooltourCard.css";
import "../CSS/GulzareQuaid.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";


function CollegeTour() {
  return (
    <>

    <div className="index-page">
      <Header />
      <main className="main">
        <div>
          {/* <div className="VirtualCollegebg">
            <div className="  container-fluid  ps-lg-5 px-0 text-light filter6 text-center">
              <div className="d-flex flex-column">
                <div>
                  <span className="fw-bold fs-md-1 fs-2 mb-5">
                    __________________________
                  </span>
                  <h1 className="text-white fw-bold text-uppercase pt-4 mb-0 d-md-block d-none">
                    <i class="bi bi-stars fs-1 fw-bold pe-3"></i>COLLEGES{" "}
                    <i class="bi bi-stars fs-1 fw-bold ps-3"></i>
                  </h1>
                  <h2 className="text-white fw-bold text-uppercase pt-4 mb-0 d-md-none d-block">
                    <i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>
                    COLLEGES{" "}
                    <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i>
                  </h2>

                  <div className="fw-bold fs-md-1 fs-2 text-center">
                    __________________________
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="container mt-5 lh-lg">
            <p>
              At SKANS , we are dedicated to providing quality education and
              fostering a nurturing environment for young learners. As a
              renowned institution in the education sector, we pride ourselves
              on offering comprehensive and well-rounded educational experiences
              that empower students to excel academically and develop essential
              life skills.
            </p>
          </div> */}
          <section class="light mt-5">
            <div class="container py-2">
              <div class="h1 text-center text-dark" id="pageHeaderTitle">
                Visit Our Campuses (COLLEGES)
              </div>

              <article class=" postcard light blue">
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src={require("../Photos/F8Campus.jpg")}
                    alt="Image Title"
                  />
                </a>
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title dark-blue fw-bold">
                    <a href="https://f8campus.skans.pk/">F8 Campus, Islamabad</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>College
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    14A-Haroon Building F8, Markaz Islamabad
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"> </i>(+92)-51-2287313
                    </li>
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>(+92)-51-2287315
                    </li>
                  </ul>
                  <a className="fw-bold" href="https://f8campus.skans.pk/">
                    Visit Campus
                  </a>
                </div>
              </article>

              <article class=" postcard light red">
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title red fw-bold">
                    <a href="https://rarcampus.skans.pk/">Potohar Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>College
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                  Potohar Campus, Main Peshawar Road Westridge, Rawalpindi Cantt.
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>+92-300-8546274
                    </li>
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>+92-51-5125155-48
                    </li>
                  </ul>
                  <a className="fw-bold" href="https://rarcampus.skans.pk/">
                    Visit Campus
                  </a>
                </div>
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src={require("../Photos/potoharcampus.png")}
                    alt="Title"
                  />
                </a>
              </article>
              <article class=" postcard light green">
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src={require("../Photos/STCAMPUS.png")}
                    alt="Image Title"
                  />
                </a>
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title green fw-bold">
                    <a href="https://stcampus.skans.pk/">Satellite Town Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>College
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    House No. 77-A,Block A,Satellite Town Rehmanabad, Rawalpindi
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>(+92)-51-2716089
                    </li>
                  </ul>
                  <a className="fw-bold" href="https://stcampus.skans.pk/">
                    Visit Campus
                  </a>
                </div>
              </article>

              <article class=" postcard light green">

                <div class="postcard__text t-dark">
                  <h1 class="postcard__title green fw-bold">
                    <a href="https://prcampus.skans.pk/">Peshawar Road Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>College
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    Peshawar Road Near Maryam Hospital, Rawalpindi
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      {/* <i class="bi bi-phone mr-2"></i>(+92)-51-2716089 */}
                    </li>
                  </ul>
                  <a className="fw-bold" href="https://prcampus.skans.pk/">
                    Visit Campus
                  </a>
                </div>
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src={require("../Photos/bgpeshawarroad.jpeg")}
                    alt="Image Title"
                  />
                </a>
              </article>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
    </>
  );
}

export default CollegeTour;

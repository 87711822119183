import React from 'react'
import "../CSS/CAprogram.css"
import "../CSS/color.css"
import Sectiontitle1 from "../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import Navbar from '../Components/navbar'
import { Link } from 'react-router-dom'
import { currentYear } from '../constant'
import CollegeNavbar from '../Components/CollegeNavbar'
import Header from '../Components/Header'
import Footer from '../Components/Footer';

function CAPrograms() {
    return (
        <>
            {/* <CollegeNavbar /> */}
            <Header/>
            <div className='programbg'>
                <div className='container-fluid  ps-5 text-light filter'>
                    <p>Home / CA Programs</p><br></br>
                    <h1 className='fw-bold text-white'>CA PROGRAM</h1>

                </div>


            </div>


            <div className=' container px-0'>
                <div className=' d-lg-block d-none'>
                    <div className=' d-flex align-items-center ' data-aos="zoom-in-down">
                        <div className='b'>

                        </div>
                        <div className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                            <h3 className='text-light fw-bold mb-0'>INTRODUCTION</h3>&nbsp;&nbsp;
                            <img height={"10%"} src={Sectiontitle2}></img>
                        </div>
                        <div className='b'>
                        </div>
                    </div>

                </div>
                <div className='d-flex flex-wrap'>
                    <div className=' col-12'>
                        <div className=' d-lg-none d-block d-flex align-items-center ' data-aos="zoom-in-down">
                            <div className='b'>

                            </div>
                            <div className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                                <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
                                <h3 className='text-light fw-bold mb-0'>INTRODUCTION</h3>&nbsp;&nbsp;
                                <img height={"10%"} src={Sectiontitle2}></img>
                            </div>
                            <div className='b'>
                            </div>
                        </div>
                        <div className=' text-justify ps-lg-5' data-aos="fade-up">
                            <ol className='lh-lg list-unstyled' >

                                <li>
                                    <div className='d-flex'>
                                        <div>
                                            <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>


                                        <div className='px-3'>
                                            ICAP is a professional body of Chartered Accountants in Pakistan. The mission of ICAP is to achieve excellence in professional competence, add value to business and economy, safeguard public interest, ensure ethical practices and good corporate governance while recognizing the needs of globalization.
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex'>
                                        <div>
                                            <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>


                                        <div className='px-3'>To become a Chartered Accountant you must be comitted, motivated and possess analytical skills, numerical ability, as well as global awareness of business and finance and personal qualities such as reliablility and discretion.
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex'>
                                        <div>
                                            <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>


                                        <div className='px-3'>
                                            Chartered Accountancy is a rewarding career and there are excellence prospects for employment within Pakistan and abroad. ICAP has worldwide contribution, with thousands of students joining every year, this prestigious national body (ICAP) feeds an average 300 qualified young professionals of yearly basis to the local and international markets.
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 container bg-lightgrey py-5 border border-top-0 border-bottom-0 border-end-0 border-5 rounded-5 border-primary' data-aos="fade-up">
                <h2 className='fw-bold textdarkblue'>CA is offered at:</h2>
                <p className='fs-5'>Islamabad Campus (Haroon Building, Opposite Utility Stores, F-8 Markaz, Islamabad)</p>
                <p className='fs-5'>Rawalpindi Campus (Raja Akram Road/Jaami Road, Rawalpindi)</p>


            </div>
            <div className='mt-5 container lh-lg' data-aos="fade-up">
                <div>
                    <h3 className='textdarkblue fw-bold'>Career Pathway</h3>

                    <p>The entry stream to this course will be as follows:</p>

                    <p className='fs-5'>Candidates who have HSSC with minimum 50% marks, ‘A’ Level with minimum two passes, or equivalent qualification and graduates with minimum 45% marks.</p>
                </div>

            </div>
            <div className='mt-5 container'>
                <div data-aos="fade-up">
                    <h3 className='textdarkblue fw-bold'>Full Time Studies Scheme</h3>
                    <ol className='lh-lg fs-6 list-unstyled'>
                        <li className=''>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue p-1 rounded-5 text-white fw-bold fs-6"></i>&nbsp;
                                </div>


                                <div className='px-3'>Candidates who have HSSC with minimum 50% marks, ‘A’ Levels with minimum two passes or equivalent qualification and graduates with minimum 45% marks. Students meet the above criteria can enter the first level (AFC) after registration.</div>
                            </div>


                        </li>
                        <li>
                            <div className='d-flex'>
                                <div>

                                    <i class="bi bi-check2-circle iconlightblue p-1 rounded-5 text-white fw-bold fs-6"></i>&nbsp;
                                </div>
                                <div className='px-3'>Before entering into the professional exam candidates pass or obtain exemption from entrance test; Assessment of fundamental Competencies (AFC). A candidate may be provisionally registered for AFC exams as fulltime student if result is awaited.</div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue p-1 rounded-5 text-white fw-bold fs-6"></i>&nbsp;</div>

                                <div className='px-3'> On successful completion of CAF, it is mandatory for the students to join an authorized training organization of the Institute for a prescribed period.</div>
                            </div>
                        </li>
                    </ol>
                </div>

            </div>

            <div className='mt-5 container lh-lg fs-6'>
                <div data-aos="fade-up">
                    <h3 className='textdarkblue fw-bold'>Trainee Scheme</h3>
                    <ol className='list-unstyled'>
                        <li>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue p-1 rounded-5 text-white fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    The scheme is designed for university graduates who want to pursue CA qualification.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue p-1 rounded-5 text-white fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Candidates who possess graduation or post-graduation degree with minimum 45% marks may directly register as a trainee after passing or obtaining exemption from AFC.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue p-1 rounded-5 text-white fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Candidates also have an option to commence training before passing AFC, but their training will be terminated if they do not pass AFC within one and a half year of training or within the maximum available attempts whichever comes earlier.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue p-1 rounded-5 text-white fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    During the training the candidate qualifies for the following stages:
                                </div></div>
                            <ul className='list-unstyled ms-5'>
                                <li>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <i class="bi bi-check2-all iconcolor p-1 rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>

                                        <div className='px-3'>
                                            Certificate in Accounting and Finance (CAF)
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <i class="bi bi-check2-all iconcolor p-1 rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>

                                        <div className='px-3'>
                                            Certified Finance and Accounting Professional (CFAP)
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <i class="bi bi-check2-all iconcolor p-1 rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>

                                        <div className='px-3'>
                                            Multi Subject Assessments (MSA)
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <i class="bi bi-check2-all iconcolor p-1 rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>

                                        <div className='px-3'>
                                            Financial Reporting Professional Competence
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <i class="bi bi-check2-all iconcolor p-1 rounded-5 fw-bold fs-4"></i>&nbsp;
                                        </div>

                                        <div className='px-3'>
                                            Audit professional competence.
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>




            <div className='mt-5 container lh-lg'>
                <div data-aos="fade-up">
                    <h3 className='textdarkblue fw-bold'>ICAP REGISTRATION & Eligibility Criteria</h3>
                    <p className='fs-6'>
                        <span className='fw-bold'>Note:</span> After admission in SKANS, Students must register with ICAP by fulfilling the following requirements.
                    </p>

                    <ol className='list-unstyled'>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Student Registration
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Paper exemption (if applicable)
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    {/* <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i> */}
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Examination with ICAP
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    ICAP Financial Assistance (Endowment Fund as date announced by ICAP)
                                </div>
                            </div>
                        </li>

                    </ol>
                    <ul>
                        <li className='fw-bold'>
                            After admission in SKANS, student must submit his/her ICAP registration form within one week, attached with the following documents.
                        </li>
                    </ul>
                    <ol className='list-unstyled'>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    ICAP Fee voucher (Must be submitted in any branch of Faisal Bank Rs.13500/-).
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Matriculation Certificate Copy.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Intermediate Certificate Copy.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    A-level result sheet (if applying on A-level basis).
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Graduation Degree Copy (if applying on Graduation basis)
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    ID Card Copy or Form-B copy
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    3 Photographs with blue background 1 x 1
                                </div>
                            </div>
                        </li>

                    </ol>
                </div>

            </div>







            <div className='mt-5 container lh-lg'>
                <div data-aos="fade-up">
                    <h3 className='fw-bold textdarkblue'>Exemptions to New Students</h3>
                    <p className='fw-bold'>The exemptions on the basis of academic qualification are available to the following categories:</p>
                    <ol className='list-unstyled'>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Four year degree from Specified Degree Awarding Institute (SDAI).
                                </div>
                            </div>

                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Four year degree from universities other than SDAI.
                                </div>
                            </div>

                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    Two years degree from any universities.
                                </div>
                            </div>

                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    HSSC or Equivalents.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check2-circle iconlightblue text-white p-1 rounded-5 fw-bold fs-6"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    A Levels.
                                </div>
                            </div>
                        </li>
                    </ol>


                </div>

            </div>




            <div className='mt-5 container px-0 fsize'>
                <p className='fw-bold border border-start-0 border-end-0 border-top-0 pb-2' data-aos="fade-up">
                    Stages&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Available exemption and conditions applicable</p>

                <p className='fw-bold border border-start-0 border-end-0 border-top-0 pb-2' data-aos="fade-up">Graduate</p>
                <table class="table table-striped table-secondary" data-aos="fade-up">

                    <thead>
                        <tr>
                            <th colspan="2">4 year (SDAI*)</th>
                            {/* <th scope="col"></th> */}
                            <th scope="col">4 year (non-SDAI*)</th>
                            <th scope="col">2 year (non-SDAI*)</th>
                            <th scope="col">HSSC</th>
                            <th scope="col pe-0">A-levels</th>
                        </tr>
                    </thead>
                    <tbody className='pe-0'>
                        <tr >
                            <td className='ps-0'>AFC</td>
                            <td>Exemption from all papers of AFC Without any other condition</td>
                            <td>Exemption from all papers of AFC Minimum 65% marks in aggregate 80% marks or equivalent grades in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject</td>
                            <td>Exemption from all papers of AFC Minimum 60% marks in aggregate 75% marks or equivalent grades in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject</td>
                            <td>Exemption from all papers of AFC Minimum 70% marks in aggregate 75% marks or equivalent grades in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject</td>
                            <td className='pe-0'>Exemption from all papers of AFC Minimum two B grades B grade in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject</td>
                        </tr>
                        <tr>
                            <td className='ps-0'>CAF</td>
                            <td>Exemption from all subjects of CAF Minimum 75% marks or equivalent grades in relevant subjects Syllabus matches at least 70% with that of the prescribed syllabus of relevant subjects</td>
                            <td>Exemption from CAF-1 to 4 Minimum 65% marks in aggregate 80% marks or equivalent grades in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject.</td>
                            <td>Exemption from CAF-1 Introduction to accounting CAF-2 Introduction Economics and Finance Minimum 60% marks 75% marks or equivalent grades in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject.</td>
                            <td>Exemption from CAF-1 Introduction to accounting CAF-2 Introduction Economics and Finance Minimum 70% marks 75% marks or equivalent grades in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject.</td>
                            <td className='pe-0'>Exemption from CAF-1 Introduction to accounting CAF-2 Introduction Economics and Finance Minimum two B grades B Grade in relevant subject Syllabus matches at least 70% with the prescribed syllabus of relevant subject.</td>
                        </tr>
                    </tbody>
                </table>
                <p data-aos="fade-up" className='fw-bold border border-start-0 border-end-0 border-top-0 pb-2'>SDAIs are degree awarding institutes recognized by the Institute on the basis of a pre-defined criteria and evaluation by the Institute. The degree courses of SDAIs are designed in close coordination with the Institute.</p>

            </div>

            <div className='mt-5 container lh-lg' data-aos="fade-up">
                <h3 className='textdarkblue fw-bold'>Exemptions for other Professional Qualification</h3>
                <p> ICAP offers following exemptions to the International and Local Accountancy Bodies.</p>
            </div>



            <div>

                <div className='mt-5 container fsize'>

                    <table class="table table-info table-striped table-secondary " data-aos="fade-up">

                        <thead>
                            <tr>
                                <th colspan="3">Stages and Papers</th>
                                {/* <th scope="col"></th> */}
                                <th scope="col">ACCA</th>
                                <th scope="col">CIMA</th>
                                <th scope="col">CIMAP</th>
                                <th scope="col">PIPFA</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th colspan="7">Assessment of Fundamental Competencies (AFC)</th>
                                {/* <th scope="col"></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>AFC-1</td>
                                <td>Functional English</td>
                                <td></td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                            </tr>
                            <tr>
                                <td>AFC-2</td>
                                <td>Business Communication</td>
                                <td></td>
                                <td>--</td>
                                <td>--</td>
                                <td>Y</td>
                                <td>Y</td>
                            </tr>
                            <tr>
                                <td>AFC-3</td>
                                <td>Quantitative Techniques</td>
                                <td></td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                            </tr>
                            <tr>
                                <td>AFC-4</td>
                                <td>Introduction to Information Technology</td>
                                <td></td>
                                <td>--</td>
                                <td>--</td>
                                <td>Y</td>
                                <td>Y</td>
                            </tr>

                        </tbody>




                        <thead>
                            <tr>
                                <th colspan="7">Certificate in Accounting and Finance (CAF)</th>
                                {/* <th scope="col"></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CAF-1</td>
                                <td>Introduction to Accounting</td>
                                <td></td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                            </tr>
                            <tr>
                                <td>CAF-2</td>
                                <td>Introduction to Economics & Financen</td>
                                <td></td>
                                <td>--</td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                            </tr>
                            <tr>
                                <td>CAF-3</td>
                                <td>Bussiness Law</td>
                                <td></td>
                                <td>--</td>
                                <td>--</td>
                                <td>Y</td>
                                <td>Y</td>
                            </tr>
                            <tr>
                                <td>CAF-4</td>
                                <td>Business Management & Behavioral Studies</td>
                                <td></td>
                                <td>--</td>
                                <td>--</td>
                                <td>Y</td>
                                <td>--</td>
                            </tr>
                            <tr>
                                <td>CAF-5</td>
                                <td>Financial Accounting and Reporting – I</td>
                                <td></td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>--</td>
                            </tr>
                            <tr>
                                <td>CAF-6</td>
                                <td>Principles of taxation</td>
                                <td></td>
                                <td>Y</td>
                                <td>--</td>
                                <td>Y</td>
                                <td>--</td>
                            </tr>

                            <tr>
                                <td>CAF-7</td>
                                <td>Financial Accounting and Reporting – II</td>
                                <td></td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>--</td>
                            </tr>

                            <tr>
                                <td>CAF-8</td>
                                <td>Cost and Management Accounting</td>
                                <td></td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>Y</td>
                                <td>--</td>
                            </tr>
                            <tr>
                                <td>CAF-9</td>
                                <td>Audit and Assurance</td>
                                <td></td>
                                <td>Y</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr>

                        </tbody>
                    </table>
                    <p data-aos="fade-up" className='fw-bold border border-start-0 border-end-0 border-top-0 pb-2'>The exemptin from CAF-6 ‘Principles of Taxation’ shall be granted to candidates who have passed F6 Taxation – Pakistan variant and exemption from ‘CAF-9 Audit and Assurance’ shall be granted to candidates who have passed P7 Advanced Auditing</p>

                </div>
            </div>












            <div className='mt-5 container lh-lg ' data-aos="fade-up">
                <h3 className='textdarkblue fw-bold'>Paper to Attempt</h3>
            </div>
            <div>

                <div className='mt-5 container fsize'>

                    <table class="table table-primary table-striped " data-aos="fade-up">

                        <thead>
                            <tr>
                                <th scope="col">Stages</th>
                                <th colspan="2">Eligibility</th>
                                {/* <th scope="col"></th> */}

                                <th colspan="3">Maximum number of paper permitted in one session</th>
                                <th colspan="2">Minimum training period to be served</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>AFC</td>
                                <td>After Registration</td>
                                <td></td>
                                <td>No Limit</td>
                                <td></td>
                                <td></td>
                                <td>None</td>

                            </tr>
                            <tr>
                                <td>CAF</td>
                                <td>After passing or obtaining exemption from AFC</td>
                                <td></td>
                                <td>5 Papers</td>
                                <td></td>
                                <td></td>
                                <td>None</td>

                            </tr>
                            <tr>
                                <td>CFAP</td>
                                <td>After passing or obtaining exemption from CAF</td>
                                <td></td>
                                <td>No Limit</td>
                                <td></td>
                                <td></td>
                                <td>1.5 Year</td>

                            </tr>
                            <tr>
                                <td>MSA-1</td>
                                <td>After passing the following CFAP papers:
                                    <ul>
                                        <li>
                                            Advanced Accounting and Financial Reporting
                                        </li>
                                        <li>
                                            Corporate Laws
                                        </li>
                                        <li>
                                            Advanced Taxation
                                        </li>
                                    </ul>
                                </td>

                                <td></td>
                                <td>N/A</td>
                                <td></td>
                                <td></td>
                                <td>1.5 Year</td>

                            </tr>


                            <tr>
                                <td>MSA-2</td>
                                <td>After passing the following CFAP papers:
                                    <ul>
                                        <li>
                                            Advanced Accounting and Financial Reporting
                                        </li>
                                        <li>
                                            Corporate Laws
                                        </li>
                                        <li>
                                            Audit, Assurance and Related Services
                                        </li>
                                    </ul>
                                </td>

                                <td></td>
                                <td>N/A</td>
                                <td></td>
                                <td></td>
                                <td>1.5 Year</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>









            <div className='mt-5 container lh-lg'>
                <h3 className='textdarkblue fw-bold' data-aos="fade-up">Maximum number of Attempts</h3>
            </div>
            <div>

                <div className='mt-5 container '>

                    <table class="table table-dark table-striped " data-aos="fade-up">

                        <thead>
                            <tr>
                                <th scope="col">Stages</th>
                                <th colspan="2">Maximum permitted attempts</th>
                                {/* <th scope="col"></th> */}

                                <th scope="col">Additional attempts</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>AFC</td>
                                <td>Three attempts per paper</td>
                                <td></td>
                                <td>None</td>
                            </tr>

                            <tr>
                                <td>CAF</td>
                                <td>Six attempts per paper</td>
                                <td></td>
                                <td>Two more attempts after using all permitted attempts per paper for those who have maximum two papers left.</td>
                            </tr>
                            <tr>
                                <td>CFAP</td>
                                <td>Six attempts per paper</td>
                                <td></td>
                                <td>Two more attempts after using all permitted attempts per paper for those who have maximum two papers left.</td>
                            </tr>
                            <tr>
                                <td>MSA</td>
                                <td>Unlimited</td>
                                <td></td>
                                <td>Not Applicable</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>





            <div className='mt-5 container lh-lg'>
                <h3 className='textdarkblue fw-bold' data-aos="fade-up">Examination Session</h3>
            </div>
            <div>

                <div className='mt-5 container lh-lg'>

                    <table class="table table-dark table-striped " data-aos="fade-up">

                        <thead>
                            <tr>
                                <th scope="col">Stages</th>
                                <th colspan="2">Sessions</th>
                                {/* <th scope="col"></th> */}

                                <th scope="col">Month</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>AFC </td>
                                <td>Spring
                                    <td>Autumn</td>
                                </td>
                                <td></td>
                                <td>March & June
                                    <td>
                                        September & December
                                    </td>
                                </td>
                            </tr>

                            <tr>
                                <td>CAF</td>
                                <td>Six attempts per paper</td>
                                <td></td>
                                <td>Two more attempts after using all permitted attempts per paper for those who have maximum two papers left.</td>
                            </tr>
                            <tr>
                                <td>CFAP</td>
                                <td>Six attempts per paper</td>
                                <td></td>
                                <td>Two more attempts after using all permitted attempts per paper for those who have maximum two papers left.</td>
                            </tr>
                            <tr>
                                <td>MSA</td>
                                <td>Unlimited</td>
                                <td></td>
                                <td>Not Applicable</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>







            <div className='mt-5 container-lg d-md-block d-none'>
                <div class="row">
                    <div class="col-lg-12" data-aos="fade-up">
                        <h3 class="text-darkblue fw-bold">Examination Schedule</h3>
                        <p>Form submission dates for students who have NOT appeared in the preceding session AFC stage examinations (w.e.f. Winter 2015)</p>
                    </div>
                </div>
                <div class="row fs-6">
                    <div class="col-lg-12 px-0">
                        <table class="table table-primary table-striped " data-aos="fade-up">

                            <thead>
                                <tr>
                                    <th scope="col">Session</th>
                                    <th scope='col'>Tentative Examination Dates</th>
                                    {/* <th scope="col"></th> */}

                                    <th scope="col">Commencement date of forms submission</th>
                                    <th colspan="3" className='text-center'>Last date for form submission</th>

                                    <th colspan="2">Expected Announcement date</th>
                                </tr>
                            </thead>


                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    {/* <th scope="col"></th> */}

                                    <th></th>
                                    <th>without late fee</th>
                                    <th>100% late fee</th>
                                    <th>200% late fee</th>
                                    <th>Provisional Result</th>
                                    <th>Result</th>

                                </tr>
                            </thead>

                            <tbody className=''>
                                <tr>
                                    <td className='fw-bold fs-6'>AFC</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tr>

                                <tr>
                                    <td>Winter 2015</td>
                                    <td>14-19 Dec, 2015</td>
                                    <td>14 Sep 2015</td>
                                    <td>12 Oct 2015</td>
                                    <td>19 Oct 2015</td>
                                    <td>26 Oct 2015</td>
                                    <td>11 Jan 2016</td>
                                    <td>1 Feb 2016</td>
                                </tr>


                                <tr>
                                    <td>Spring 2016</td>
                                    <td>14-19 Mar 2016</td>
                                    <td>14 Dec 2015</td>
                                    <td>11 Jan 2016</td>
                                    <td>18 Jan 2016</td>
                                    <td>25 Jan 2016</td>
                                    <td>11 Apr 2016</td>
                                    <td>17 May 2016</td>
                                </tr>


                                <tr>
                                    <td>Summer 2016</td>
                                    <td>13-18Jun2016</td>
                                    <td>14 Mar 16</td>
                                    <td>11 Apr 2016</td>
                                    <td>18 Apr 2016</td>
                                    <td>25 Apr 2016</td>
                                    <td>11 Jul 2016</td>
                                    <td>1 Aug 2016</td>
                                </tr>



                                <tr>
                                    <td>Autumn 2016</td>
                                    <td>12-17 Sep 2016</td>
                                    <td>13 Jun 2016</td>
                                    <td>11 Jul 2016</td>
                                    <td>18 Jul 2016</td>
                                    <td>25 Jul 2016</td>
                                    <td>10 Oct 2016</td>
                                    <td>15 Nov 2016</td>
                                </tr>




                                <tr>
                                    <td>Winter 2016</td>
                                    <td>12-17 Dec 2016</td>
                                    <td>12 Sep 2016</td>
                                    <td>10 Oct 2016</td>
                                    <td>17 Oct 2016</td>
                                    <td>24 Oct 2016</td>
                                    <td>9 Jan 2017</td>
                                    <td>30 Jan 2017</td>
                                </tr>




                                <tr>
                                    <td>Spring 2017</td>
                                    <td>13-18 Mar 2017</td>
                                    <td>12 Dec 2016</td>
                                    <td>9 Jan 2017</td>
                                    <td>16 Jan 2017</td>
                                    <td>23 Jan 2017</td>
                                    <td>10 Apr 2017</td>
                                    <td>16 May 2017</td>
                                </tr>




                                <tr>
                                    <td>Summer 2017</td>
                                    <td>12-17Jun 2017</td>
                                    <td>13 Mar 2017</td>
                                    <td>10 Apr 2017</td>
                                    <td>17 Apr 2017</td>
                                    <td>24 Apr 2017</td>
                                    <td>10 Jul 2017</td>
                                    <td>31 Jul 2017</td>
                                </tr>


                                <tr>
                                    <td className='fw-bold fs-6'>CAF</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>


                                <tr>
                                    <td>Autumn 2015</td>
                                    <td>07-12 Sep 2015</td>
                                    <td>8 Jun 2015</td>
                                    <td>6 Jul 2015</td>
                                    <td>13 Jul 2015</td>
                                    <td>22 Jul 2015</td>
                                    <td>--</td>
                                    <td>17 Nov 2015</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <p data-aos="fade-up" class="fw-bold border border-start-0 border-end-0 border-top-0 pb-2">All the dates are subject to change without prior notice</p>
                    </div>
                </div>

            </div>









            <div className='mt-5 container lh-lg'>
                <h3 data-aos="fade-up" className='textdarkblue fw-bold'>PCSC – Presentation and Communication Skills Courses</h3>

                <ol className='list-unstyled' data-aos="fade-up">
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>


                            <div className='px-3'>

                                SKANS is the first institute to be recognized as the Approved PCSC Provider (APP) by ICAP!
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>


                            <div className='px-3'>

                                PCSC – Presentation and Communication Skills Courses 1 & 2 have been made mandatory by ICAP for CA candidates, with effect from May 5, 2015 and these courses can only be delivered by an Approved PCSC Provider. Being certified as an APP, is a clear reflection of the quality standards met by SKANS for these training programmes.
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                SKANS has exceeded its commitment of providing competent professional accountants, who are not only technically sound but also in possession of professional attitude; trained and groomed in qualities that employers expect from managers of tomorrow.
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Our trainers are dedicated professionals who provide guidelines that help in developing an adaptable personality that proves to be a competitive edge for the participants, in the practical world.
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                The Presentation and Communication Skills Courses offered by SKANS will cover areas ranging from CV writing, interviewing, presentation and public speaking skills to maintaining a professional attitude and managing conflict, through a hands-on training approach.
                            </div>
                        </div>
                    </li>

                </ol>
            </div>






            <div className='container lh-lg' data-aos="fade-up">
                <h5 className='fw-bold py-3'>Further details are:</h5>
                <h3 className='textdarkblue fw-bold pb-3'>Presentation and Communication Skills Course 1</h3>
                <h5 className='fw-bold'>Learning outcomes:</h5>

                <ol className='list-unstyled' data-aos="fade-up">
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Develop yourself intellectually by dealing effectively with feedback
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Develop your interpersonal skills by mastering the art of business communication
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Develop your written communication skills in terms of report writing etc. taking into account the latest guidelines
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Develop the required professionally skeptic attitude and learn how to think outside-the-box to deal with difficult work situations and ethical dilemmas
                            </div>
                        </div></li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Get the latest guidelines on the do’s and dont’s of the job market
                            </div>
                        </div>
                    </li>

                </ol>
                <h5 className='fw-bold py-3' data-aos="fade-up">Relevance</h5>

                <ol className='list-unstyled' data-aos="fade-up">
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Candidates who have started their training contract before January 1, 2016 with an approved employer are required to complete this course along-side their practical training
                            </div>
                        </div>

                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Candidates who will start their training contract on or after January 1, 2016, are required to complete this course before starting the practical training
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Candidates who had started their training on or after January 1, 2015 are required to complete this course along-side their practical training but before attempting any CFAP (previously Module E) exams.
                            </div>
                        </div>
                    </li>
                </ol>
            </div>

            <div className='container lh-lg mt-5' data-aos="fade-up">
                <h3 className='textdarkblue fw-bold'>Presentation and Communication Skills Course 2</h3>
                <h5 className='fw-bold'>Learning outcomes</h5>
                <p>In addition to the objectives of PCSC 1</p>

                <ol className='list-unstyled' data-aos="fade-up">
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Develop your reasoning, critical analysis, innovative thinking and provide solutions to the practical problems
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Develop your leadership skills of consultation and negotiation to influence positively the people you will work with

                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Refresh your ethical guidelines to deal with real work-place issues
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Learn to adapt your attitude according to the different corporate cultures you come across.
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Learn to overcome challenges using a creative and innovative approach
                            </div>
                        </div>
                    </li>
                </ol>
                <h5 className='fw-bold' data-aos="fade-up">Relevance</h5>

                <ol className='list-unstyled' data-aos="fade-up">
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Candidates who have started their training contract on or after January 1, 2015 or will start their training on or after January 1, 2016 have to undergo this course along-side their training and before attempting MSA (previously Module F) exams.
                            </div>

                        </div>
                    </li>
                    <li>
                        <div className='d-flex align-items-center'>
                            <div>
                                <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                            </div>

                            <div className='px-3'>
                                Candidates who had not completed their training by December 31, 2014 have to undergo this course along-side their training and before June 30, 2016.
                            </div>
                        </div>
                    </li>
                </ol>
            </div>





            <div className='container lh-lg' data-aos="fade-up">
                <h3 className='textdarkblue fw-bold'>ICAP Fee Structure</h3>
                <p>CA Reg. Fee Rs. 9700/- PPT Exemption Rs. 3100/-</p>


                <div data-aos="fade-up" className='mt-5 container bg-lightgrey py-5 border border-top-0 border-bottom-0 border-end-0 border-5 rounded-5 border-primary'>
                    <p className='fs-4 fw-bold'>𝙄𝘾𝘼𝙋 𝙍𝙚𝙜𝙞𝙤𝙣𝙖𝙡 𝙊𝙛𝙛𝙞𝙘𝙚</p>
                    <p className='fs-4 fw-bold'>𝙈𝙖𝙪𝙫𝙚 𝘼𝙧𝙚𝙖 𝙂-10/4 𝙄𝙨𝙙</p>
                    <p className='fs-4 fw-bold'>051-111-000-422(𝙎𝙖𝙩𝙪𝙧𝙙𝙖𝙮,𝙎𝙪𝙣𝙙𝙖𝙮 𝙊𝙛𝙛)</p>

                </div>


                <div className='container lh-lg my-5'>
                    <h3 className='textdarkblue fw-bold' data-aos="fade-up">ICAP ENDOWMENT FUND</h3>
                    <ol className='list-unstyled' data-aos="fade-up">
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    ICAP offers financial assistance in the form of interest free educational loan to its students who have a sound academic record and find it difficult to afford educational expenses. Students who are desirous of availing the facility may apply on a prescribed application to the chairman loan award committee of ICAP
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    A Student must submit his/her ICAP financial assistance application form on and before the dates announced by ICAP Expected Dates in Months: May and December
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <i class="bi bi-check-circle-fill iconcolor  rounded-5 fw-bold fs-4"></i>&nbsp;
                                </div>

                                <div className='px-3'>
                                    <span className='fw-bold'>Note:</span> All requirements are given on ICAP Financial Assistance form (It can be downloaded form the ICAP official website www.icap.org.pk)
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
            <Footer/>
            {/* <div className='bgdarkblue py-4'>
                <div className='d-flex justify-content-around text-white'>
                    <div>
                        © Copyright {currentYear} - All rights reserved
                    </div>
                    <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                </div>


            </div> */}

        </>
    )
}

export default CAPrograms

// import React from 'react'
// import "../CSS/imagehover.css"
// import "../CSS/collegetour.css"
// import { Link } from 'react-router-dom'
// import Sectiontitle1 from '../Photos/section-title-shape-1.png';
// import Sectiontitle2 from '../Photos/section-title-shape-2.png';
// import SchoolNavbar from '../Components/SchoolNavbar';
// import Header from '../Components/Header';




// function SchoolTour() {
//     return (


        
//         <div>
//             {/* <SchoolNavbar/> */}
//             <Header/>
//             <div className='VirtualSchoolbg'>
//                 <div className='  container-fluid  ps-lg-5 px-0 text-light filter6 text-center'>
//                     <div className='d-flex flex-column'>

//                         <div>
//                             <span className='fw-bold fs-md-1 fs-2 mb-5'>
//                                 __________________________
//                             </span>
//                             <h1 className='fw-bold text-uppercase pt-4 mb-0 d-md-block d-none'><i class="bi bi-stars fs-1 fw-bold pe-3"></i>Schools <i class="bi bi-stars fs-1 fw-bold ps-3"></i></h1>
//                             <h2 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>Schools <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h2>


//                             <div className='fw-bold fs-md-1 fs-2 text-center'>
//                                 __________________________
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>

//             <div className='container mt-5 lh-lg'>
//                 <p>At SKANS , we are dedicated to providing quality education and fostering a nurturing environment for young learners. As a renowned institution in the education sector, we pride ourselves on offering comprehensive and well-rounded educational experiences that empower students to excel academically and develop essential life skills.</p>
//             </div>
//             <div className='container rounded-4'>
//                 <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >1</h3>
//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mb-5 mt-2 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">Harley Street Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/HarleyCampus.png")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/JuniorHarleyCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6 '>
//                         <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3403920.547683911!2d73.043105!3d33.575684!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df9376ee69112d%3A0x56f5e38beb6c2f75!2sSKANS%20Schools%20System%20Harley%20Campus!5e0!3m2!1sen!2sus!4v1692607144941!5m2!1sen!2sus" width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
//                     </div>
//                 </div>

//             </div>

//             <div className='container rounded-4'>
//             <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >2</h3>

//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mt-3 mb-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">JUNIOR CHAKRI ROAD CAMPUS</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/ChakriCampus.png")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/JuniorChakriRoad' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6 '>
//                         <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3325.3415963354832!2d73.000458!3d33.5445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df935b355c1845%3A0xda355a4a1a330808!2sSkans%20school%20system!5e0!3m2!1sen!2sus!4v1692610681540!5m2!1sen!2sus " width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
//                     </div>
//                 </div>

//             </div>

//             <div className='container rounded-4'>
//             <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >3</h3>

//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">JUNIOR WESTRIDGE CAMPUS</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/JuniorWestridge.png")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/JuniorWestridgeCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6 '>
//                         <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106349.85796825575!2d72.978106950164!3d33.594066440527165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38df95002ace9d01%3A0xc61fcce66fdef971!2sSkans%20International%20Islamic%20School%20Class%201-2%2C%20310A%20I%20Service%20Rd%2C%20Westridge%201%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.605236!2d73.023287!5e0!3m2!1sen!2s!4v1714917769259!5m2!1sen!2s" width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
//                     </div>
//                 </div>

//             </div>

//             <div className='container rounded-4'>
//             <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >4</h3>

//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">Senior Westridge Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/SeniorWestridgeCampus.png")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/SeniorWestridgeCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6 '>
//                     <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d7403.733333034863!2d73.01424069182859!3d33.611141944357016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d33.6392986!2d73.0772357!4m5!1s0x38df95454cae1491%3A0xe0e8f7942ac9253!2sHouse%20no%2C%20Skans%20International%20Islamic%20School%2C%20310-A%20Main%20Peshawar%20Rd%2C%20Westridge%201%2C%20Rawalpindi!3m2!1d33.6066394!2d73.0184862!5e0!3m2!1sen!2s!4v1715544844250!5m2!1sen!2s" width="550" height="400"  style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

//                         {/* <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106338.0499483005!2d72.94330480059205!3d33.603642286689485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38df95ca5f4bb601%3A0x18bf86bcf8bfd9b1!2sJ23G%2BC7J%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.603669599999996!2d73.025706!5e0!3m2!1sen!2s!4v1711686353545!5m2!1sen!2s" width="550" height="400" style={{ border: 0, borderRadius: "0px", }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
//                     </div>
//                 </div>

//             </div>




//             <div className='container rounded-4'>
//             <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >5</h3>

//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">Golra Mor Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/GolraMor - Copy.png")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/GolraMorCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6 '>
//                     <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26578.3693973423!2d72.9657287!3d33.6235618!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df971f4accbb67%3A0x548fe651929da710!2sSKANS%20International%20Islamic%20School&#39;s!5e0!3m2!1sen!2s!4v1699871788806!5m2!1sen!2s" width="550" height="400"  style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


//                     </div>
//                 </div>

//             </div>



//         <div className='container rounded-4'>
//             <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >6</h3>

//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">SKANS PWD Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/PWDcampus.JPG")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/PWDCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6'>

//                     <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106318.69717320455!2d73.02391750129357!3d33.61933143040843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfed431e1b520d%3A0xf2f2788e3d47e5c2!2sskans%20pwd%20campus!3m2!1d33.5728222!2d73.14698539999999!5e0!3m2!1sen!2s!4v1712122103531!5m2!1sen!2s" width="550" height="400"  style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


//                     </div>
//                 </div>

//         </div>


//         <div className='container rounded-4'>
//             <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >7</h3>

//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">AECHS Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/AECHSCampus.png")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/AirportCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6'>

//                     <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106308.90435853692!2d73.01587275164856!3d33.62726792723422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfedd25af0b447%3A0xad845b487020e809!2sSkans%20School%20System%20AECHS%20Campus%2C%20337%20Street%2017%2C%20Sector%201%20Airport%20Employees%20CHS%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.5880636!2d73.129655!5e0!3m2!1sen!2s!4v1712123400984!5m2!1sen!2s" width="550" height="400"  style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>



//                     </div>
//                 </div>

//         </div>

//         <div className='container rounded-4'>
//             <h3 className='mt-5 rounded-circle text-center text-white fw-bold p-1 c-listitem__num ' >8</h3>

//                 <div className="d-flex align-items-center" data-aos="fade-up">
//                     <div className="b"></div>
//                     <div data-aos="zoom-in" className="mb-5 mt-3 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded px-5">
//                         <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;&nbsp;&nbsp;
//                         <h3 className="text-light fw-bold mb-0 text-uppercase">Peshawar Road Campus</h3>&nbsp;&nbsp;&nbsp;&nbsp;
//                         <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
//                     </div>
//                     <div className="b"></div>
//                 </div>
//                 <div className='d-flex flex-wrap mt-3  mb-3 ' data-aos="fade-up">
//                     <div className='d-flex flex-column align-items-center  col-12 col-lg-6  rounded-start-4 '>
                      
//                         <div className='imghvr-slide-right'>
//                             <img src={require("../Photos/skanspeshawarroad.png")} className='rounded-start-4 ' width="550" height="400">

//                             </img>
//                             <figcaption className='d-flex justify-content-center align-items-center z-3'>
//                                 <Link to='/PeshawarRoadCampus' class="buttonvirtual outline fw-bold">VISIT CAMPUS</Link>
//                             </figcaption><a href="javascript:;"></a>
//                         </div>
//                     </div>
//                     <div className='d-flex align-items-center col-12 col-lg-6'>
//                     <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106336.92322917306!2d72.94300280063285!3d33.60455588632352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38df95075e33eed1%3A0x9bf67d21feef8c2c!2sJ23G%2BR5J%2C%20Westridge%201%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.604582799999996!2d73.02540379999999!5e0!3m2!1sen!2s!4v1715754698465!5m2!1sen!2s" width="550" height="400"  style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
//                     {/* <iframe className='col-12' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d106308.90435853692!2d73.01587275164856!3d33.62726792723422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x38dfedd25af0b447%3A0xad845b487020e809!2sSkans%20School%20System%20AECHS%20Campus%2C%20337%20Street%2017%2C%20Sector%201%20Airport%20Employees%20CHS%2C%20Rawalpindi%2C%20Punjab%2046000!3m2!1d33.5880636!2d73.129655!5e0!3m2!1sen!2s!4v1712123400984!5m2!1sen!2s" width="550" height="400"  style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}



//                     </div>
//                 </div>

//         </div>

//         </div>
//     )
// }

// export default SchoolTour



import React from "react";
import "../CSS/collegetour.css";
import "../CSS/schooltourCard.css";
import "../CSS/GulzareQuaid.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";


function SchoolTour() {
  return (
    <div className="index-page">
      <Header />
      <main className="main">
        <div>
          {/* <div className="VirtualSchoolbg">
            <div className="  container-fluid  ps-lg-5 px-0 text-light filter6 text-center">
              <div className="d-flex flex-column">
                <div>
                  <span className="fw-bold fs-md-1 fs-2 mb-5">
                    __________________________
                  </span>
                  <h1 className="text-white fw-bold text-uppercase pt-4 mb-0 d-md-block d-none">
                    <i class="bi bi-stars fs-1 fw-bold pe-3"></i>Schools{" "}
                    <i class="bi bi-stars fs-1 fw-bold ps-3"></i>
                  </h1>
                  <h2 className="text-white fw-bold text-uppercase pt-4 mb-0 d-md-none d-block">
                    <i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>Schools{" "}
                    <i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i>
                  </h2>

                  <div className="fw-bold fs-md-1 fs-2 text-center">
                    __________________________
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 lh-lg">
            <p>
              At SKANS , we are dedicated to providing quality education and
              fostering a nurturing environment for young learners. As a
              renowned institution in the education sector, we pride ourselves
              on offering comprehensive and well-rounded educational experiences
              that empower students to excel academically and develop essential
              life skills.
            </p>
          </div> */}
          <section class="light mt-5">
            <div class="container py-2">
              <div class="h1 text-center text-dark" id="pageHeaderTitle">
                Visit Our Campuses (SCHOOLS)
              </div>

              <article class=" postcard light blue">
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src="https://skans.pk/static/media/HarleyCampus.dd1ead3edfb2133c6bfa.png"
                    alt="Image Title"
                  />
                </a>
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title dark-blue fw-bold">
                    <a href="#">Harley Street Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="fas fa-calendar-alt mr-2"></i>Junior School
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    House No 53-B Harley Street Campus – Rawalpindi
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"> </i> (+92)-332-8922225
                    </li>
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>(+92)-51-5176138
                    </li>
                    <li class="tag__item">
                      <a href="#">
                        <i class="bi bi-phone mr-2"></i>(+92)-51-5176066
                      </a>
                    </li>
                  </ul>
                  <a
                    className="fw-bold"
                    href="https://hscampus.skansschool.edu.pk/"
                  >
                    Visit Campus
                  </a>
                </div>
              </article>

              <article class=" postcard light red">
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title red fw-bold">
                    <a href="#">AECHS Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>Junior School
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    337 Street 17, Sector 1 Airport Employees CHS, Rawalpindi
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>(051) 8468475
                    </li>
                  </ul>
                  <a
                    className="fw-bold"
                    href="https://aechscampus.skansschool.edu.pk/ "
                  >
                    Visit Campus
                  </a>
                </div>
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src="https://skans.pk/static/media/AECHSCampus.5e09fde8ca5b15a21845.png"
                    alt="Image Title"
                  />
                </a>
              </article>
              <article class=" postcard light green">
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src={require("../Photos/GolraMor.png")}
                    alt="Image Title"
                  />
                </a>
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title green fw-bold">
                    <a href="#">Golra Mor Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>Junior School
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    Moaz Rd, Main Peshawar Rd, near Jamia Masjid Suqaina Golrah
                    Morh, Islamabad
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>(051) 2227475
                    </li>
                  </ul>
                  <a
                    className="fw-bold"
                    href="https://golrajuniorcampus.skansschool.edu.pk/"
                  >
                    Visit Campus
                  </a>
                </div>
              </article>
              <article class="postcard light yellow">
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title yellow fw-bold">
                    <a href="#">Chakri Road Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>Junior School
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    Raja Bashir Plaza, Moza Hayal Ranail, Opposite Pir Mehar Ali
                    Shah Town, Chakri Road Rawalpindi.
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>0310-3331131
                    </li>
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>051- 5575082
                    </li>
                  </ul>
                  <a
                    className="fw-bold"
                    href="http://chakricampus.skansschool.edu.pk/"
                  >
                    Visit Campus
                  </a>
                </div>
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src="	https://skans.pk/static/media/ChakriCampus.00a19e1fcc8c20aa2319.png"
                    alt="Image Title"
                  />
                </a>
              </article>
              <article class="postcard light yellow">
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src={require("../Photos/JuniorWestridge.png")}
                    alt="Image Title"
                  />
                </a>
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title yellow fw-bold">
                    <a href="#">Junior Westridge Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>Junior School
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    H.No. 9-B, Hali Road, Westridge-1, Rawalpindi
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>051-8773091
                    </li>
                  </ul>
                  <a
                    className="fw-bold"
                    href="https://westridgejuniorcampus.skansschool.edu.pk/"
                  >
                    Visit Campus
                  </a>
                </div>
              </article>

              <article class="postcard light yellow">
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title yellow fw-bold">
                    <a href="#">Senior Westridge Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>Senior School
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    91 Hali Road, Street no. 8, Westridge-1, Rawalpindi
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>(051) 8355546
                    </li>
                  </ul>
                  <a
                    className="fw-bold"
                    href=" https://westridgeseniorcampus.skansschool.edu.pk/"
                  >
                    Visit Campus
                  </a>
                </div>
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src="https://skans.pk/static/media/SeniorWestridgeCampus.3dd96887a59e813a4623.png"
                    alt="Image Title"
                  />
                </a>
              </article>

              <article class="postcard light yellow">
                <a class="postcard__img_link" href="#">
                  <img
                    class="postcard__img"
                    src={require("../Photos/PWDcampus.JPG")}
                    alt="Image Title"
                  />
                </a>
                <div class="postcard__text t-dark">
                  <h1 class="postcard__title yellow fw-bold">
                    <a href="#">PWD Campus</a>
                  </h1>
                  <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                      <i class="bi bi-calendar-alt mr-2"></i>Junior School
                    </time>
                  </div>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt">
                    233, Block A Sector A PWD, Islamabad, Punjab
                  </div>
                  <ul class="postcard__tagbox">
                    <li class="tag__item">
                      <i class="bi bi-phone mr-2"></i>(051) 8355546
                    </li>
                  </ul>
                  <a
                    className="fw-bold"
                    href="https://pwdcampus.skansschool.edu.pk/"
                  >
                    Visit Campus
                  </a>
                </div>
              </article>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default SchoolTour;

import React from "react";
import partner1 from "../Photos/aff_logo_1.jpeg";
import partner2 from "../Photos/aff_logo_2.jpeg";
import partner3 from "../Photos/aff_logo_3.jpeg";
import partner4 from "../Photos/aff_logo_4.jpeg";
import { currentYear } from "../constant";
function Footer() {
  return (
    <footer id="footer" class="footer dark-background">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-lg-4 col-md-6 footer-about">
            <a class="logo d-flex align-items-center">
              <span class="">
                <img
                  className="img-fluid "
                  width={"200px"}
                  src="https://skans.pk/static/media/Skanslogo1.f87f92f0dafaa656209b.png"
                ></img>
              </span>
            </a>
            <div class="footer-contact pt-3">
              <p>- Nazim-ud-din Rd, F-8 Markaz, Islamabad</p>
              <p>- 304, Bangash Plaza, Main Peshawar Rd, near Maryam Memorial Hospital, Rawalpindi</p>
              <p>- Potohar Campus, Main Peshawar Road Westridge, Rawalpindi Cantt.</p>
              <p class="mt-3">
                <strong>Phone:</strong> <span>+92-51-2287314-15</span>
              </p>
              <p>
                <strong>Email:</strong> <span>info@skans.pk</span>
              </p>
            </div>
            <div class="social-links d-flex mt-4">
              {/* <a href="">
                <i class="bi bi-twitter-x"></i>
              </a> */}
              <a href="https://www.facebook.com/Accountancy">
                <i class="bi bi-facebook"></i>
              </a>
              <a href="https://www.instagram.com/skansf8isb">
                <i class="bi bi-instagram"></i>
              </a>
              {/* <a href="">
                <i class="bi bi-linkedin"></i>
              </a> */}
            </div>
          </div>

          <div class="col-lg-2 col-md-12 footer-newsletter">
            <h4>Our Schools</h4>
            <ul>
              <li>
                <a href="https://hscampus.skansschool.edu.pk/">
                  Harley Street Campus
                </a>
              </li>
              <li>
                <a href="https://golrajuniorcampus.skansschool.edu.pk/">
                  Golra Junior Campus
                </a>
              </li>
              <li>
                <a href="https://aechscampus.skansschool.edu.pk/">
                  AECHS Campus
                </a>
              </li>
              <li>
                <a href="https://pwdcampus.skansschool.edu.pk/">PWD Campus</a>
              </li>
              <li>
                <a href="https://westridgeeyecampus.skansschool.edu.pk/">
                  Westridge EYE Campus
                </a>
              </li>
              <li>
                <a href="https://westridgejuniorcampus.skansschool.edu.pk/">
                  Westridge Junior Campus
                </a>
              </li>
              <li>
                <a href="https://westridgeseniorcampus.skansschool.edu.pk/">
                  Westridge Senior Campus
                </a>
              </li>
              <li>
                <a href="http://chakricampus.skansschool.edu.pk/">
                  Chakri Campus
                </a>
              </li>
              <li>
                <a href="https://golraseniorcampus.skansschool.edu.pk/">
                  Golra Senior Campus
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-3 footer-newsletter">
            <h4>Our Colleges</h4>
            <ul>
              <li>
                <a href="https://stcampus.skans.pk/">Sattelitte Town Campus</a>
              </li>
              <li>
                <a href="https://f8campus.skans.pk/">F8 Markaz Campus</a>
              </li>
              <li>
                <a href="https://rarcampus.skans.pk/"> Potohar Campus</a>
              </li>
              <li>
                <a href="https://prcampus.skans.pk/">Peshawar Road Campus</a>
              </li>
              <li>
                <a href="https://skansinstituteoftechnology.pk/">SKANS Institute of Technology</a>
              </li>
            </ul>
          </div>
          <div className=" col-lg-2 col-md-3 footer-links">
            <h4>Affiliations</h4>
            <ul className=" d-flex">
              <li className="pe-2">
                <img className="w-100" src={partner1} />
              </li>
              <li className="pt-0">
                <img className="w-100" src={partner2} />
              </li>
            </ul>
            <ul className=" d-flex  ">
              <li className="pe-2">
                <img className="w-100" src={partner3} />
              </li>
              <li className="pt-0">
                <img className="w-100" src={partner4} />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span> <strong class="px-1 sitename">SKANS {currentYear}</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        {/* <div class="credits">
          <a href="skans.pk">SKANS</a>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
